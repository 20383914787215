import React from "react"
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { XGrid } from '@components/atoms'
import { GridItem, Hero } from '@components/molecules'
import { TransitionMask } from '@components/organisms'
import { FloatingContactForm } from '@components/forms'
import { H2 } from '@components/atoms/BlockRenderer'
import { PageProps } from '@types'
import { getCdnPath } from '@utils'
import { Helmet } from 'react-helmet'


const LocalWorksPage = ({data: { 
  sanityPage: {
    heroType, 
    heroHeight,
    heroImageConditional, 
    heroVideoConditional,
    heroTextConditional,
    heroFX,
    gridItems,
    inverted,
    shareImage,
    description,
    title,
  }
}}: PageProps) => {
  const parsedTitle = `TWA: ${title}`
  const meta = [
    { property: "og:title", content: parsedTitle },
    { name: "twitter:title", content: parsedTitle },
    { name: "description", content: description },
    { name: "twitter:description", content: description },
    { property: "og:description", content: description },
  ]
  if(shareImage) {
    meta.push({ property: "og:image", content:  getCdnPath(`${shareImage.asset.url}?w=640&fm=jpg`) })
    meta.push({ name: "twitter:image", content:  getCdnPath(`${shareImage.asset.url}?w=640&fm=jpg`) })
  }

  // @ts-ignore
  return (
    <>
      <Helmet {...{meta}} title={parsedTitle} >
        <title>{parsedTitle}</title>
      </Helmet>

      <Hero {...{ heroType, heroHeight, heroImageConditional, heroVideoConditional, heroTextConditional, heroFX }} />
      
      <StyledXGrid>
        {gridItems.filter(p => !!p).map((item) => (
          <StyledGridItem key={item._key} {...item} rows={item.layout.layout.rows || 1} /> 
        ))}
        <Gap />
      </StyledXGrid>
            
      <TransitionMask {...{ inverted }} />
        
      <FloatingContactForm
        subject="Local Works contact"
        howText="How can Local Works help your brand?"
        hiddenInputs={['website']}
      >
        <H2>Think you might qualify? Enter your details below to be considered for participation in Local Works:</H2>
      </FloatingContactForm>
    </>
  )
}


const StyledGridItem = styled(props => <GridItem {...props} />)<{ rows?: number }>`
  grid-row-end: span ${props => props.rows} !important;
`



const StyledXGrid = styled(props => <XGrid {...props} />)`
  grid-template-rows: 60vh 40vh;
  @media only screen and (min-width: 744px) {
    grid-template-rows: 75vh 25vh;
  }
`



const Gap = styled.div`
  grid-column: 57 / span 5;
  @media only screen and (min-width: 744px) {
    grid-column: 84 / span 1;
    grid-row: 1 / span 2;
  }
`


export const query = graphql`
  query LocalWorksPageQuery {
    sanityPage(slug: {current: {eq: "local-works"}}) {
      ...pageFields
    }
  }
`

export default LocalWorksPage
